<template>
    <div>
        <div class="row justify-content-center">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                    <div class="my-3" v-show="loadData">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>

                    </div>
                    <img :src="'/images/empty.png'" v-show="dataNotExist" alt="" width="300">
                    <h1 class="text-muted" v-show="dataNotExist">Data don't exist</h1>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" v-show="dataExist">
                <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Edit Coupon</h1>
                <hr>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                        <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                            <form @submit.prevent="postCoupon" class="row p-2">
                                <div class="col-xl-6 form-group mb-3">
                                    <label for="">Coupon Code</label>
                                    <input v-model="coupons.code" type="text" required class="form-control text-grey-900 font-xsss fw-600">                        
                                </div>
                                <div class="col-xl-6 form-group mb-3">
                                    <label for="">Coupon Amount</label>
                                    <input v-model="coupons.quota" type="text" required class="form-control text-grey-900 font-xsss fw-600">                        
                                </div>
                                <div class="col-12">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th colspan="2">Regular</th>
                                                <th colspan="2">Special Features</th>
                                            </tr>
                                        </thead>
                                        <tbody class="">
                                            <tr>
                                                <td width="5%" class="text-center">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="coupons.cycle_1" @change="checkCheckbox(1)" class="form-check-input">
                                                    </div>
                                                </td>
                                                <td>Term 1</td>
                                                <td width="5%" class="text-center">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="coupons.cycle_1_special" :disabled="!coupons.cycle_1" class="form-check-input">
                                                    </div>
                                                </td>
                                                <td>Term 1 Special</td>
                                            </tr>
                                            <tr>
                                                <td width="5%" class="text-center">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="coupons.cycle_2" @change="checkCheckbox(2)" class="form-check-input">
                                                    </div>
                                                </td>
                                                <td>Term 2</td>
                                                <td width="5%" class="text-center">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="coupons.cycle_2_special" :disabled="!coupons.cycle_2" class="form-check-input">
                                                    </div>
                                                </td>
                                                <td>Term 2 Special</td>
                                            </tr>
                                            <tr>
                                                <td width="5%" class="text-center">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="coupons.cycle_3" @change="checkCheckbox(3)" class="form-check-input">
                                                    </div>
                                                </td>
                                                <td>Term 3</td>
                                                <td width="5%" class="text-center">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="coupons.cycle_3_special" :disabled="!coupons.cycle_3" class="form-check-input">
                                                    </div>
                                                </td>
                                                <td>Term 3 Special</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-xl-12 form-group text-right my-4">
                                    <router-link to="/admin/courses" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                                    <input type="submit" class="btn btn-warning text-white" value="Submit">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: "CouponEdit",
    data(){
        return{
            dataExist: false,
            dataNotExist: false,
            paramsId: this.$route.params.idCourse,
            id: this.$route.params.idCoupon,
            coupons:{}
        }
    },
    created(){
        this.getCoupon()
    },
    methods:{
        checkCheckbox(cycle) {
            if(cycle == 1) {
                if(this.coupons.cycle_1 == false) {
                    this.coupons.cycle_1_special = false
                }
            } else if (cycle == 2) {
                if(this.coupons.cycle_2 == false) {
                    this.coupons.cycle_2_special = false
                }
            } else if (cycle == 3) {
                if(this.coupons.cycle_3 == false) {
                    this.coupons.cycle_3_special = false
                }
            }
        },
        async getCoupon() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/coupon/detail?id=${this.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.loadData = false
                if (res.data.length == 0) {
                    this.dataNotExist = true
                } else {
                    this.dataExist = true
                    this.coupons = res.data
                }
            }).catch((err) => {
                if (err.response.status == 404){
                    this.dataNotExist = true
                }
            })
        },
        async postCoupon(){
            var data = {
                id: this.id,
                code : this.coupons.code,
                slug: this.paramsId,
                quota : this.coupons.quota,
                cycle_1: this.coupons.cycle_1,
                cycle_2: this.coupons.cycle_2,
                cycle_3: this.coupons.cycle_3,
                cycle_1_special: this.coupons.cycle_1_special,
                cycle_2_special: this.coupons.cycle_2_special,
                cycle_3_special: this.coupons.cycle_3_special,
                _method: 'patch',
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/coupon`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'Success Update New Coupon !',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'CouponCoursesAdmin' })
            }).catch((err) => {
                if (err.response.status == 422){
                    this.validationErrors = Object.values(err.response.data.errors);
                    // this.$toastr.error(this.validationErrors[0][0], 'failed Create New Course !');
                    this.$swal({
                        toast: true,
                        title: 'Failed Edit Coupon !',
                        text: this.validationErrors[0][0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    }
}
</script>